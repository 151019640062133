export default (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  formSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 500,

    '& h5': {
      marginBottom: theme.spacing()
    }
  },
  field: {
    width: 450
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  textField: {
    width: 400,
    maxWidth: '100%',
    marginTop: theme.spacing()
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
    marginRight: theme.spacing(2)
  },
  dateField: {
    marginTop: theme.spacing(0.5)
  },
  multiSelectFieldLabel: {
    marginBottom: theme.spacing()
  },
  multiSelectField: {
    width: 400,
    maxWidth: '100%',
    marginTop: theme.spacing(2)
  },
  selectField: {
    width: 200,
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing()
  },
  suppliersSelectField: {
    width: 400,
    maxWidth: '100%',
    marginTop: theme.spacing(2)
  },
  suppliersLabel: {
    marginBottom: theme.spacing()
  },
  nameField: {
    width: 200,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing()
  },
  zipCodeField: {
    width: 100,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing()
  },
  cityTextField: {
    width: 300 - theme.spacing(),
    marginTop: theme.spacing(2)
  },
  actionButtons: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing()
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing()
  }
})
